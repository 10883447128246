// eslint-disable-next-line no-redeclare,no-unused-vars
function administrateMenu(menu, menuList) {
  const {adminFunc} = window;
  if (adminFunc)
    menu.find('#menuCustomizing' + ucfirst(adminFunc)).addClass('active');
  menu.find('#menuCustomizing').next().find('.sub-link').addClass('active-fix');

  var context = function (type, target) {
    if (type == 'menu') {
      var list1 = [];
      var list2 = {};

      $.each(menuList, function (i, point) {
        var type = point.slice(0, 1);
        var key = point.slice(1);
        if (type == '|') list1.push(point.slice(1));
        else {
          var top = list1.slice(-1)[0];
          if (!list2[top]) list2[top] = [];
          list2[top].push(key);
        }
      });

      sorter({
        title: 'Menü editieren',
        fields: fieldsMenu,
        list: list1,
        list2: list2,
        save: function (result) {
          menuList = [];
          if (result.top)
            $.each(result.top, function (i, key) {
              menuList.push('|' + key);
              if (result.subs && result.subs[key])
                $.each(result.subs[key], function (i, key) {
                  menuList.push('-' + key);
                  if (result.subs2 && result.subs2[key])
                    $.each(result.subs2[key], function (i, key) {
                      menuList.push(':' + key);
                    });
                });
            });
          var menuId = 'menu' + ucfirst(role);
          fieldsMenu[menuId] = menuList;
          if (getObjectValue(fieldsMenu, ['roots', menuId]))
            menuId = getObjectValue(fieldsMenu, ['roots', menuId]);
          saveAdminSettings({
            type: 'menu',
            key: menuId,
            value: menuList.join(''),
          });
          buildMenu(null, true);
        },
      });
    } else if (type == 'edit') {
      var key = target.attr('data-ident');
      var pm = {title: key, type: 'options', id: key};
      pm.settings = $.extend({}, dialogForms.menuEdit);
      pm.row = fieldsMenu[key];
      pm.save = function (value, element, option) {
        saveAdminSettings({
          type: 'menu',
          key: key,
          sub: option,
          value: value,
        });
        fieldsMenu[key][option] = value;
        buildMenu(null, true);
      };
      var view = buildPopup(pm);
      buildForm(view.body, pm, dialogForms.menuEdit.form);
    } else if (type == 'add') {
      pm = [];
      pm.push({label: 'Variablenname'});
      pm.push({label: 'Label'});
      pm.push({label: 'Objekt'});
      // pm.push({label:'Tabelle erstellen'});
      buildPromptPopup(pm, function (key, label, object) {
        key = keyName(key);
        if (!key) return;
        var objectCheck = object ? object : key;
        if (inArray(objectCheck, ['login', 'logout', 'event', 'test'])) {
          buildPopup({content: 'Objektname nicht zulässig', warning: true});
          return;
        }
        var point = {label: label};
        if (object) point.object = object;
        fieldsMenu[key] = point;

        var menuId = 'menu' + ucfirst(user.role);
        if (getObjectValue(fieldsMenu, ['roots', menuId]))
          menuId = getObjectValue(fieldsMenu, ['roots', menuId]);
        if (target.children('.submenu')[0])
          menuList.splice(
            menuList.indexOf(
              '-' + target.find('.sub:last').attr('data-ident')
            ) + 1,
            0,
            '-' + key
          );
        else if (target.parent('.submenu')[0])
          menuList.splice(
            menuList.indexOf('-' + target.attr('data-ident')) + 1,
            0,
            '-' + key
          );
        else
          menuList.splice(
            menuList.indexOf('|' + target.attr('data-ident')) + 1,
            0,
            '-' + key
          );
        fieldsMenu['menu' + ucfirst(user.role)] = menuList;

        var add = {};
        add[key] = point;
        add[menuId] = menuList.join('');
        saveAdminSettings({type: 'menu', add: add}, function () {
          if (
            object &&
            object != key &&
            !parameter[object]['label' + ucfirst(key)]
          ) {
            saveAdminSettings({
              type: 'option',
              object: object,
              key: 'label' + ucfirst(key),
              value: label,
            });
            parameter[object]['label' + ucfirst(key)] = label;
          }
          if (!fields[object])
            ajax({object: 'settings', send: {func: 'databaseUpdate'}});
        });
        buildMenu(null, true);
        if (!parameter[object]) fields[object] = {};
      });
    }
    // else if (type == 'label' || type == 'icon'){
    // 	var key = target.attr('data-ident');
    // 	var point = fieldsMenu[key]; if (!point) return;
    // 	var pm = {}; pm[type] = point[type];
    // 	buildPromptPopup(pm,function(value){
    // 		saveAdminSettings({type:'menu',sub:key,key:type,value:value});
    // 		point[type] = value;
    // 		buildMenu(null, true);
    // 	});
    // }
  };
  contextMenu(
    menu.find('.nav-link,.sub-link'),
    {
      edit: 'Menüpunkt bearbeiten',
      add: 'Menüpunkt hinzufügen',
      menu: 'Menü editieren',
    },
    context
  );
}
